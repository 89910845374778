import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-submit-success',
  templateUrl: './submit-success.component.html',
  styleUrls: ['./submit-success.component.scss'],
})
export class SubmitSuccessComponent {

  @Input() successText: string;

  constructor(
    private modalController: ModalController,
  ) { }

  dismissModal() {
    this.modalController.dismiss();
  }
}
