import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertButton, AlertOptions } from '@ionic/core';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(
    private alertController: AlertController,
  ) { }

  showUnexpectedRequestErrorAlert() {
    return this.showBlockingAlert({
      header: 'Fehler',
      message: 'Es ist ein unerwarteter Fehler aufgetreten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }

  showScanUnexpectedErrorAlert() {
    return this.showBlockingAlert({
      header: 'Fehler',
      message: 'Der Barcode-Scanner ist auf Ihrem Gerät leider nicht verfügbar. Bitte verwenden Sie die manuelle Eingabe.',
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }

  showScanNotAllowedErrorAlert() {
    return this.showBlockingAlert({
      header: 'Fehler',
      message: 'Das Gerät verfügt über keine Kamera oder der Zugriff darauf wurde blockiert.<br><br>Bitte erteilen Sie eine Freigabe oder verwenden Sie die manuelle Eingabe.',
      buttons: [
        {
          text: 'Ok',
        }
      ]
    });
  }

  showReferenceNumberInputAlert(referenceNumberHandler: (referenceNumber: string) => any) {
    return this.showBlockingAlert({
      header: 'Referenznummer eingeben',
      message: 'Bitte geben Sie die Referenznummer Ihrer Blauen Tonne an:',
      inputs: [
        {
          name: 'referenceNumber',
          type: 'number',
          placeholder: 'Referenznummer'
        },
      ],
      buttons: [
        {
          text: 'Schließen',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Ok',
          handler: ({ referenceNumber }) => referenceNumberHandler(referenceNumber)
        }
      ]
    });
  }

  showInvalidReferenceNumberAlert(message: string, retryHandler?: AlertButton['handler']) {
    let buttons: AlertButton[];

    if (retryHandler) {
      buttons = [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Erneut versuchen',
          handler: retryHandler
        }
      ];
    } else {
      buttons = [
        {
          text: 'Ok',
        }
      ];
    }

    return this.showBlockingAlert({
      header: 'Referenznummer ungültig',
      message,
      buttons,
    });
  }

  private async showBlockingAlert(options: AlertOptions) {
    const alert = await this.alertController.create(options);

    alert.present();
    return alert.onWillDismiss();
  }

}
