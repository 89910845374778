import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { BrowserMultiFormatReader, BarcodeFormat, DecodeHintType, NotFoundException, Result, Exception } from '@zxing/library';
import { AlertService } from '~/services/alert';

@Component({
  selector: 'app-barcode-scanner',
  templateUrl: './barcode-scanner.component.html',
  styleUrls: ['./barcode-scanner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeScannerComponent implements OnInit {

  codeReader: BrowserMultiFormatReader;
  isLoading = true;

  constructor(
    private alertService: AlertService,
    private cd: ChangeDetectorRef,
    private modalController: ModalController,
  ) { }

  async ngOnInit() {
    if (!this.codeReader) {
      const hints = new Map([
        [DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_128]]
      ]);
      this.codeReader = new BrowserMultiFormatReader(hints);
    }
  }

  ionViewWillEnter() {
    this.isLoading = true;
    this.cd.detectChanges();
  }

  async ionViewDidEnter() {
    this.startScanning();
  }

  ionViewDidLeave() {
    this.stopScanning();
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  private async startScanning() {
    try {
      await this.codeReader.decodeFromVideoDevice(
        undefined,
        'video',
        (result, error) => this.handleResult(result, error),
      );
    } catch (err) {
      this.handleError(err);
    }

    this.isLoading = false;
    this.cd.detectChanges();
  }

  private async stopScanning() {
    this.codeReader.reset();
  }

  handleResult(result: Result, err: Exception) {
    if (err instanceof NotFoundException || !result) {
      return;
    }

    if (err) {
      console.error(err);
      return;
    }

    console.log({ result });
    const code = result.getText();

    try {
      parseInt(code, 10);
    } catch (e) {
      console.error('Code not parsable as Int: ', code, e);
      return;
    }

    this.stopScanning();
    this.modalController.dismiss(code);
  }

  handleError(err: Error) {
    console.error(err);
    this.dismissModal();

    if (err && err.name === 'NotAllowedError') {
      this.alertService.showScanNotAllowedErrorAlert();
    } else {
      this.alertService.showScanUnexpectedErrorAlert();
    }
  }
}
