import { ModuleWithProviders, NgModule } from '@angular/core';

import { ModalService } from '~/services/modal/modal.service';

import {
  BarcodeScannerComponent,
  BarcodeScannerComponentModule,
  SubmitSuccessComponent,
  SubmitSuccessComponentModule,
} from '~/modals';

import { ModalRegistry } from './modal-registry';

const modalModules = [
  BarcodeScannerComponentModule,
  SubmitSuccessComponentModule,
];

const modalComponents = {
  BarcodeScannerComponent,
  SubmitSuccessComponent,
};

// used to prevent circular dependencies
ModalRegistry.set(modalComponents);

@NgModule({
  imports: modalModules,
})
export class ModalServiceModule {
  static forRoot(): ModuleWithProviders<ModalServiceModule> {
    return {
      ngModule: ModalServiceModule,
      providers: [
        ModalService,
      ],
    };
  }
}
