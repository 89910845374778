import { ComponentRef } from '@ionic/core';

interface ComponentRegistry {
  [componentName: string]: ComponentRef;
}

// used to prevent circular dependencies
export class ModalRegistry {

  private static components: ComponentRegistry = {};

  static set(components: ComponentRegistry) {
    this.components = components;
  }

  static get(name: string) {
    return this.components[name];
  }

}
