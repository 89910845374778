import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
})
export class FooterLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
