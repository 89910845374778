import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SubmitSuccessComponent } from './submit-success.component';
import { FooterLinksComponentModule } from '~/components/footer-links/footer-links.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, FooterLinksComponentModule],
  declarations: [SubmitSuccessComponent],
  exports: [SubmitSuccessComponent]
})
export class SubmitSuccessComponentModule {}
