import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';

import { ModalRegistry } from '~/services/modal/modal-registry';


@Injectable()
export class ModalService {

  constructor(
    private modalController: ModalController,
  ) {
  }

  async openBarcodeScannerModal(): Promise<OverlayEventDetail<string | null>> {
    const submitSuccessModal = await this.modalController.create({
      component: ModalRegistry.get('BarcodeScannerComponent'),
    });

    setTimeout(() => {
      submitSuccessModal.present();
    });

    return submitSuccessModal.onDidDismiss();
  }

  async openSubmitSuccessModal(successText: string): Promise<any> {
    const submitSuccessModal = await this.modalController.create({
      component: ModalRegistry.get('SubmitSuccessComponent'),
      componentProps: {
        successText,
      },
    });

    setTimeout(() => {
      submitSuccessModal.present();
    });

    await submitSuccessModal.onDidDismiss();
  }

}
